module.exports = {
  siteTitle: 'Leo Ertuna',
  siteDescription: `TekLeo software solutions for your business`,
  keyWords: ['software', 'development', 'solution', 'developer', 'machine learning', 'artificial intelligence', 'backend', 'frontend', 'mobile', 'application'],
  authorName: 'TekLeo',
  githubUsername: 'jpleorx',
  authorAvatar: '/images/leo-ertuna.png',
  authorDescription: `
    Full-stack Developer, AI Researcher, Crypto Enthusiast, Petrolhead
    <br></br>
    My areas of interest spread very broadly across various fields.
    From AI, machine learning, computer vision and natural language processing - to technical analysis and cryptocurrency mining.
    From building microservice architecture and developing scalable backend services - to frontend or mobile applications.  
    <br></br>
    I am constantly evolving, researching, learning new tools, investigating new technologies and expanding my skillset to previously untouched areas.
    <br></br>
    I enjoy sharing my expertise and solutions with the world, so I constantly write articles for my <a href="https://tekleo.net/blog" target="_blank">Blog</a> and <a href="https://medium.com/@leo.ertuna" target="_blank">Medium</a> page on various technologies. This is my way of giving back to the community, and hopefully with those tutorials added to the shared knowledge pool we all will build better software tomorrow. 
    <br></br>
    Want me to build something for your business? Feel free to contact me! I'm open to new projects, consulting, startups and any side-gigs. 
<!--    Ready to build your next project with me?-->
    `,
  skills: [
    {
      name: 'AI & Machine Leaning',
      level: 90
    },
    {
      name: 'Backend with Python (FastAPI, Flask)',
      level: 85,
    },
    {
      name: 'Backend with Java (Spring)',
      level: 80,
    },
    {
      name: 'Frontend with Angular',
      level: 60,
    },
    {
      name: 'Mobile with Flutter',
      level: 75
    },
    {
      name: 'DevOps',
      level: 60
    },
    {
      name: 'Software Architecture',
      level: 75
    },
    {
      name: 'Scalable Cloud & Distributed Systems',
      level: 75,
    },
    {
      name: 'High Concurrency Services',
      level: 70,
    },
    {
      name: 'Trading & Technical Analysis',
      level: 80
    },
  ],
  projects: [
    // {
    //   name: "TekLeo NLP",
    //   begin: {
    //     month: "jan",
    //     year: "2021"
    //   },
    //   duration: null,
    //   description: "Cloud AI service that specializes in NLP (natural language processing). "
    // },
    {
      name: "PointaMeet",
      begin: {
        month: "may",
        year: "2020"
      },
      duration: "1 year",
      description: "This was a dating mobile application (yet another Tinder clone) developed in Flutter, with backend as Spring microservices. This app included all the features you'd typically expect from a dating app. Swipes left/right, superlikes, location based matching, profile pictures, in-app chat and etc."
    },
    {
      name: "*****",
      begin: {
        month: "apr",
        year: "2020"
      },
      duration: null,
      description: "I built a legal documents parsing engine as a part of an enterprise property management web application. Processing scanned PDF documents into digital form: document segmentation, object detection with custom AI models trained on problem-specific datasets, optical character recognition (OCR). Then some more analytics were added: document summarization, semantic analysis, highlighting key topics. And much-much more."
    },
    {
      name: "*****",
      begin: {
        month: "oct",
        year: "2019"
      },
      duration: "10 months",
      description: "Developed a web based container orchestration service, tailored towards distributed machine learning and GPU workloads. Designed various ml training and inference jobs to benchmark different hardware and possible ways of distributing the workload."
    },
    {
      name: "RoboIG",
      begin: {
        month: 'may',
        year: '2018'
      },
      duration: '1 year',
      description: "An online tool for managing your Instagram account, with various automation options. The service logged in under user's account and liked / commented on other users' posts, as well as watched stories and replied to direct messages. I built both backend services and frontend application for this project."
    },
    {
      name: "*****",
      begin: {
        month: "mar",
        year: "2018"
      },
      duration: "2 years",
      description: "Was part of a team that built a service for cryptocurrency miners to control their rigs and increase their revenue by switching between multiple coins. I was mostly responsible for developing an analytics tool that monitored profitability of mining various coins and predicted which coin will be better to mine for each mining rig."
    }
  ],
  jobs: [
    /* more jobs here */
    {
      company: "Gympass",
      begin: {
        month: 'sep',
        year: '2019'
      },
      duration: null,
      occupation: "Frontend developer",
      description: "I am part of the Corporate team, responsible for the development and maintenance of the employee management platform, giving more and more autonomy to partner companies."

    },  {
      company: "Lendico",
      begin: {
        month: 'apr',
        year: '2018'
      },
      duration: null,
      occupation: "Frontend developer",
      description: "I integrate the Frontend team responsible for developing and maintaining the online lending platform."

    }, {
      company: "Anapro",
      begin: {
        month: 'dec',
        year: '2016'
      },
      duration: '1 yr e 5 mos',
      occupation: "Fullstack developer",
      description: "Development and maintenance, corrective and preventive, of web applications for the real estate market."
    }, {
      company: "Anapro",
      begin: {
        month: 'set',
        year: '2012'
      },
      duration: '4 yrs e 3 mos',
      occupation: "Support Technician",
      description: "Responsible for the implementation and parameterization of the system, training and customer support. Acting also in person in real estate launches guaranteeing the success and good use of the tool."

    },
  ],
  portifolio: [
    {
      image: "/images/gatsby-starter-cv.png",
      name: "Gatsby starter CV template",
      description: "Lorem ipsum doremi sadasdq a soipo saweoujeq sdoewsa KJLJGSAD jkefvd wseporndovp seropdvspod sfgjkdspr dsklvpdsfip. Lorem ipsum doremi sadasdq a soipo saweoujeq sdoewsa KJLJGSAD jkefvd wseporndovp seropdvspod sfgjkdspr dsklvpdsfip",
      url: "https://www.gatsbyjs.org/starters/santosfrancisco/gatsby-starter-cv/"
    },
    {
      image: "/images/awesome-grid.png",
      name: "Responsive grid for ReactJS",
      description: "Lorem ipsum doremi sadasdq a soipo saweoujeq sdoewsa KJLJGSAD jkefvd wseporndovp seropdvspod sfgjkdspr dsklvpdsfip. Lorem ipsum doremi sadasdq a soipo saweoujeq sdoewsa KJLJGSAD jkefvd wseporndovp seropdvspod sfgjkdspr dsklvpdsfip",
      url: "https://github.com/santosfrancisco/react-awesome-styled-grid"
    },
    /* more portifolio items here */
  ],
  social: {
    // twitter: "https://twitter.com/_franciscodf",
    linkedin: "https://www.linkedin.com/in/leo-ertuna-14b539187",
    github: "https://github.com/jpleorx",
    email: "leo.ertuna@gmail.com",
    medium: "https://medium.com/@leo.ertuna",
    blog: "https://tekleo.net/blog",
    playstore: "https://play.google.com/store/apps/dev?id=8974295855250363302",
  },
  siteUrl: 'https://tekleo.net/',
   pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-193478190-1',
  background_color: '#fafafa',
  theme_color: '#25303B',
  fontColor: "#000000cc",
  enableDarkmode: false,
  display: 'minimal-ui',
  icon: 'src/assets/favicon-new.png',
  headerLinks: [
    {
      label: 'CV',
      url: '/',
    },
    // {
    //   label: 'Projects',
    //   url: '/portifolio',
    // },
    {
      label: 'Blog',
      url: 'https://tekleo.net/blog'
    }
  ]
}